import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTableV5 } from 'mdbreact';
import { CircularProgress, Checkbox, TextField, Autocomplete, Drawer } from "@mui/material";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { styled } from '@mui/material/styles';
import { medicationSkuList } from "../dynamicController";

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-20%`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      maxWidth: "80%"
    }),
  }),
);

const PatientRegistrationReport = ({ role, token }) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productList, setProductList] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [showIcMrn, setShowIcMrn] = useState(false);

  useEffect(() => {
    if (role && token) {
      axios.post("/api/admin/patientRegistration", { role, token, productList: [] })
        .then(res => {
          if (res.data.showIcMrn) {
            setShowIcMrn(res.data.showIcMrn);
          }
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          if (err.response.data.error === "Token Expired") {
            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
              .then(click => {
                if (click.isConfirmed) {
                  navigate('/admin/login');
                }
              })
          } else {
            mySwal.fire("Error", err.response.data.error, "error")
          }
        });
    } else {
      navigate('/admin/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const handleFilter = () => {
    setLoading(true);

    let sendThis = {
      role,
      token,
      productList
    }

    if (startDate && endDate) {
      sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
      sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
    }

    axios.post('/api/admin/patientRegistration', sendThis)
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setProductList([]);
    setLoading(true);

    axios.post('/api/admin/patientRegistration', { role, token, productList: [] })
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  let hcpTableData = {
    columns: [
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "patientMobile",
        label: "Patient Mobile",
        sort: "asc"
      },
      {
        field: "patientCountryCode",
        label: "Nationality by Mobile",
        sort: "asc"
      },
      {
        field: "patientMrn",
        label: "Patient MRN/IC",
        sort: "asc"
      },
      {
        field: "registrationDate",
        label: "Registration Date",
        sort: "asc"
      },
      {
        field: "month",
        label: "Month",
        sort: "asc"
      },
      {
        field: "year",
        label: "Year",
        sort: "asc"
      },
      {
        field: "registeredProduct1",
        label: "Registered Product 1",
        sort: "asc"
      },
      {
        field: "registeredProduct2",
        label: "Registered Product 2",
        sort: "asc"
      },
      {
        field: "registeredProduct3",
        label: "Registered Product 3",
        sort: "asc"
      },
      {
        field: "registeredProduct4",
        label: "Registered Product 4",
        sort: "asc"
      },
      {
        field: "registeredProduct5",
        label: "Registered Product 5",
        sort: "asc"
      },
      {
        field: "registeredProduct6",
        label: "Registered Product 6",
        sort: "asc"
      },
      {
        field: "registeredProduct7",
        label: "Registered Product 7",
        sort: "asc"
      },
      {
        field: "registeredProduct8",
        label: "Registered Product 8",
        sort: "asc"
      },
      {
        field: "registeredProduct9",
        label: "Registered Product 9",
        sort: "asc"
      },
      {
        field: "registeredProduct10",
        label: "Registered Product 10",
        sort: "asc"
      },
      {
        field: "startDate",
        label: "Start Date",
        sort: "asc"
      },
      {
        field: "endDate",
        label: "End Date",
        sort: "asc"
      },
      {
        field: "registeredByAccount",
        label: "Registered by Account",
        sort: "asc"
      },
      {
        field: "accountSubmitted1",
        label: "Account Submitted 1",
        sort: "asc"
      },
      {
        field: "accountSubmitted2",
        label: "Account Submitted 2",
        sort: "asc"
      },
      {
        field: "accountSubmitted3",
        label: "Account Submitted 3",
        sort: "asc"
      },
    ],
    rows: data ? data : []
  };

  let hcpIcMrnTableData = {
    columns: [
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "patientMobile",
        label: "Patient Mobile",
        sort: "asc"
      },
      {
        field: "patientCountryCode",
        label: "Nationality by Mobile",
        sort: "asc"
      },
      {
        field: "patientIc",
        label: "Patient IC",
        sort: "asc"
      },
      {
        field: "patientMrn",
        label: "Patient MRN",
        sort: "asc"
      },
      {
        field: "registrationDate",
        label: "Registration Date",
        sort: "asc"
      },
      {
        field: "month",
        label: "Month",
        sort: "asc"
      },
      {
        field: "year",
        label: "Year",
        sort: "asc"
      },
      {
        field: "registeredProduct1",
        label: "Registered Product 1",
        sort: "asc"
      },
      {
        field: "registeredProduct2",
        label: "Registered Product 2",
        sort: "asc"
      },
      {
        field: "registeredProduct3",
        label: "Registered Product 3",
        sort: "asc"
      },
      {
        field: "registeredProduct4",
        label: "Registered Product 4",
        sort: "asc"
      },
      {
        field: "registeredProduct5",
        label: "Registered Product 5",
        sort: "asc"
      },
      {
        field: "registeredProduct6",
        label: "Registered Product 6",
        sort: "asc"
      },
      {
        field: "registeredProduct7",
        label: "Registered Product 7",
        sort: "asc"
      },
      {
        field: "registeredProduct8",
        label: "Registered Product 8",
        sort: "asc"
      },
      {
        field: "registeredProduct9",
        label: "Registered Product 9",
        sort: "asc"
      },
      {
        field: "registeredProduct10",
        label: "Registered Product 10",
        sort: "asc"
      },
      {
        field: "startDate",
        label: "Start Date",
        sort: "asc"
      },
      {
        field: "endDate",
        label: "End Date",
        sort: "asc"
      },
      {
        field: "registeredByAccount",
        label: "Registered by Account",
        sort: "asc"
      },
      {
        field: "accountSubmitted1",
        label: "Account Submitted 1",
        sort: "asc"
      },
      {
        field: "accountSubmitted2",
        label: "Account Submitted 2",
        sort: "asc"
      },
      {
        field: "accountSubmitted3",
        label: "Account Submitted 3",
        sort: "asc"
      },
    ],
    rows: data ? data : []
  };

  let adminTableData = {
    columns: [
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "registrationDate",
        label: "Registration Date",
        sort: "asc"
      },
      {
        field: "patientCountryCode",
        label: "Nationality by Mobile",
        sort: "asc"
      },
      {
        field: "month",
        label: "Month",
        sort: "asc"
      },
      {
        field: "year",
        label: "Year",
        sort: "asc"
      },
      {
        field: "registeredProduct1",
        label: "Registered Product 1",
        sort: "asc"
      },
      {
        field: "registeredProduct2",
        label: "Registered Product 2",
        sort: "asc"
      },
      {
        field: "registeredProduct3",
        label: "Registered Product 3",
        sort: "asc"
      },
      {
        field: "registeredProduct4",
        label: "Registered Product 4",
        sort: "asc"
      },
      {
        field: "registeredProduct5",
        label: "Registered Product 5",
        sort: "asc"
      },
      {
        field: "registeredProduct6",
        label: "Registered Product 6",
        sort: "asc"
      },
      {
        field: "registeredProduct7",
        label: "Registered Product 7",
        sort: "asc"
      },
      {
        field: "registeredProduct8",
        label: "Registered Product 8",
        sort: "asc"
      },
      {
        field: "registeredProduct9",
        label: "Registered Product 9",
        sort: "asc"
      },
      {
        field: "registeredProduct10",
        label: "Registered Product 10",
        sort: "asc"
      },
      {
        field: "startDate",
        label: "Start Date",
        sort: "asc"
      },
      {
        field: "endDate",
        label: "End Date",
        sort: "asc"
      },
      {
        field: "registeredByAccount",
        label: "Registered by Account",
        sort: "asc"
      },
      {
        field: "accountSubmitted1",
        label: "Account Submitted 1",
        sort: "asc"
      },
      {
        field: "accountSubmitted2",
        label: "Account Submitted 2",
        sort: "asc"
      },
      {
        field: "accountSubmitted3",
        label: "Account Submitted 3",
        sort: "asc"
      },
    ],
    rows: data ? data : []
  };

  let csv = [];

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const dataObject = {
        patientId: data[i].patientId,
        patientIc: data[i].patientIc,
        patientMrn: data[i].patientMrn,
        registrationDate: data[i].registrationDate,
        month: data[i].month,
        year: data[i].year,
        registeredProduct1: data[i].registeredProduct1,
        registeredProduct2: data[i].registeredProduct2,
        registeredProduct3: data[i].registeredProduct3,
        registeredProduct4: data[i].registeredProduct4,
        registeredProduct5: data[i].registeredProduct5,
        registeredProduct6: data[i].registeredProduct6,
        registeredProduct7: data[i].registeredProduct7,
        registeredProduct8: data[i].registeredProduct8,
        registeredProduct9: data[i].registeredProduct9,
        registeredProduct10: data[i].registeredProduct10,
        startDate: data[i].startDate,
        endDate: data[i].endDate,
        registeredByAccount: data[i].registeredByAccount,
        accountSubmitted1: data[i].accountSubmitted1,
        accountSubmitted2: data[i].accountSubmitted2,
        accountSubmitted3: data[i].accountSubmitted3
      }
      if (role === "hcp" || role === "dev") {
        dataObject.patientMobile = `=""${data[i].patientMobile}""`;
      }
      if (role === "admin" || role === "dev" || role === "hcp") {
        dataObject.patientCountryCode = `=""${data[i].patientCountryCode}""`;
      }
      csv.push(dataObject);
    }
  }

  const hcpCsvData = {
    headers: [
      { label: "Patient ID", key: "patientId" },
      { label: "Patient Mobile", key: "patientMobile" },
      { label: "Patient Country Code", key: "patientCountryCode" },
      { label: "Patient MRN/IC", key: "patientMrn" },
      { label: "Registration Date", key: "registrationDate" },
      { label: "Month", key: "month" },
      { label: "Year", key: "year" },
      { label: "Registered Product 1", key: "registeredProduct1" },
      { label: "Registered Product 2", key: "registeredProduct2" },
      { label: "Registered Product 3", key: "registeredProduct3" },
      { label: "Registered Product 4", key: "registeredProduct4" },
      { label: "Registered Product 5", key: "registeredProduct5" },
      { label: "Registered Product 6", key: "registeredProduct6" },
      { label: "Registered Product 7", key: "registeredProduct7" },
      { label: "Registered Product 8", key: "registeredProduct8" },
      { label: "Registered Product 9", key: "registeredProduct9" },
      { label: "Registered Product 10", key: "registeredProduct10" },
      { label: "Start Date", key: "startDate" },
      { label: "End Date", key: "endDate" },
      { label: "Registered by Account", key: "registeredByAccount" },
      { label: "Account Submitted 1", key: "accountSubmitted1" },
      { label: "Account Submitted 2", key: "accountSubmitted2" },
      { label: "Account Submitted 3", key: "accountSubmitted3" },
    ],
    data: csv
  }

  const hcpIcMrnCsvData = {
    headers: [
      { label: "Patient ID", key: "patientId" },
      { label: "Patient Mobile", key: "patientMobile" },
      { label: "Patient IC", key: "patientIc" },
      { label: "Patient MRN", key: "patientMrn" },
      { label: "Registration Date", key: "registrationDate" },
      { label: "Month", key: "month" },
      { label: "Year", key: "year" },
      { label: "Registered Product 1", key: "registeredProduct1" },
      { label: "Registered Product 2", key: "registeredProduct2" },
      { label: "Registered Product 3", key: "registeredProduct3" },
      { label: "Registered Product 4", key: "registeredProduct4" },
      { label: "Registered Product 5", key: "registeredProduct5" },
      { label: "Registered Product 6", key: "registeredProduct6" },
      { label: "Registered Product 7", key: "registeredProduct7" },
      { label: "Registered Product 8", key: "registeredProduct8" },
      { label: "Registered Product 9", key: "registeredProduct9" },
      { label: "Registered Product 10", key: "registeredProduct10" },
      { label: "Start Date", key: "startDate" },
      { label: "End Date", key: "endDate" },
      { label: "Registered by Account", key: "registeredByAccount" },
      { label: "Account Submitted 1", key: "accountSubmitted1" },
      { label: "Account Submitted 2", key: "accountSubmitted2" },
      { label: "Account Submitted 3", key: "accountSubmitted3" },
    ],
    data: csv
  }

  const adminCsvData = {
    headers: [
      { label: "Patient ID", key: "patientId" },
      { label: "Registration Date", key: "registrationDate" },
      { label: "Patient Country Code", key: "patientCountryCode" },
      { label: "Month", key: "month" },
      { label: "Year", key: "year" },
      { label: "Registered Product 1", key: "registeredProduct1" },
      { label: "Registered Product 2", key: "registeredProduct2" },
      { label: "Registered Product 3", key: "registeredProduct3" },
      { label: "Registered Product 4", key: "registeredProduct4" },
      { label: "Registered Product 5", key: "registeredProduct5" },
      { label: "Registered Product 6", key: "registeredProduct6" },
      { label: "Registered Product 7", key: "registeredProduct7" },
      { label: "Registered Product 8", key: "registeredProduct8" },
      { label: "Registered Product 9", key: "registeredProduct9" },
      { label: "Registered Product 10", key: "registeredProduct10" },
      { label: "Start Date", key: "startDate" },
      { label: "End Date", key: "endDate" },
      { label: "Registered by Account", key: "registeredByAccount" },
      { label: "Account Submitted 1", key: "accountSubmitted1" },
      { label: "Account Submitted 2", key: "accountSubmitted2" },
      { label: "Account Submitted 3", key: "accountSubmitted3" },
    ],
    data: csv
  }

  return (
    <div className="d-flex">

      <Drawer
        sx={{
          minWidth: "20%",
          '& .MuiDrawer-paper': {
            minWidth: "20%",
            boxSizing: 'border-box',
            minHeight: "100vh",
            backgroundColor: "#FE7072",
          },
        }}
        anchor="left"
        open={openSidebar}
        variant="persistent">
        <Sidebar />
      </Drawer>

      <Main open={openSidebar}>
        <div className="mt-3 px-3">
          <Header title="PATIENT REGISTRATION REPORT" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

          <div className="report-header mt-3 d-flex py-3 px-3 justify-content-between">
            <div className="col-4">
              <p>This report shows registered patients and their annual cycle date upon purchase of any products</p>
            </div>
            <div>
              <div className="d-flex">
                <div>
                  <DatePicker placeholderText="Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control py-3" />
                </div>
                <div className="ps-2">
                  <Autocomplete
                    multiple
                    id="sku"
                    options={medicationSkuList}
                    disableCloseOnSelect
                    value={productList}
                    onChange={(e, value) => {
                      setProductList(value);
                    }}
                    renderOption={(props, medicationSkuList, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          checked={selected}
                        />
                        {medicationSkuList}
                      </li>
                    )}
                    style={{ width: 250, backgroundColor: "white" }}
                    renderInput={(params) => (
                      <TextField {...params} label="SKU" />
                    )}
                  />
                </div>
                <div className="ps-2">
                  <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                </div>
                <div className="ps-2">
                  <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                </div>
                <div className="ps-2">
                  <CSVLink className="btn btn-primary shadow-none admin-button" data={csv} headers={role === "hcp" && showIcMrn ? hcpIcMrnCsvData.headers : role === "hcp" || role === "dev" ? hcpCsvData.headers : adminCsvData.headers} filename={`patient_registration_report_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </div>
          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div>
                <MDBDataTableV5 data={role === "hcp" && showIcMrn ? hcpIcMrnTableData : role === "hcp" || role === "dev" ? hcpTableData : adminTableData} hover responsive bordered />
              </div>
            )
          }
        </div>
      </Main>

    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.admin.token,
    role: state.admin.role
  };
};

export default connect(mapStateToProps, null)(PatientRegistrationReport);
