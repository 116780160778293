import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTableV5 } from 'mdbreact';
import { CircularProgress, Checkbox, TextField, Autocomplete, Drawer } from "@mui/material";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { styled } from '@mui/material/styles';
import { medicationSkuList } from "../dynamicController";

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-20%`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      maxWidth: "80%"
    }),
  }),
);

const PatientRedemptionReport = ({ role, token }) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productList, setProductList] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [hideHcpRedemptionCode, setHideHcpRedemptionCode] = useState(false);
  const [showIcMrn, setShowIcMrn] = useState(false);

  useEffect(() => {
    if (role && token) {
      axios.post("/api/admin/patientRedemption", { role, token, productList: [] })
        .then(res => {
          if (res.data.hideHcpRedemptionCode) {
            setHideHcpRedemptionCode(res.data.hideHcpRedemptionCode)
          }
          if(res.data.showIcMrn) {
            setShowIcMrn(res.data.showIcMrn)
          }
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          if (err.response.data.error === "Token Expired") {
            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
              .then(click => {
                if (click.isConfirmed) {
                  navigate('/admin/login');
                }
              })
          } else {
            mySwal.fire("Error", err.response.data.error, "error")
          }
        });
    } else {
      navigate('/admin/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const handleFilter = () => {
    setLoading(true);

    let sendThis = {
      role,
      token,
      productList
    }

    if (startDate && endDate) {
      sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
      sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
    }

    axios.post('/api/admin/patientRedemption', sendThis)
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setProductList([]);
    setLoading(true);

    axios.post('/api/admin/patientRedemption', { role, token, productList: [] })
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  let hcpTableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "redemptionCode",
        label: "Redemption Code",
        sort: "asc"
      },
      {
        field: "redeemStatus",
        label: "Redeem Status",
        sort: "asc"
      },
      {
        field: "redemptionType",
        label: "Redemption Type",
        sort: "asc"
      },
      {
        field: "patientMobile",
        label: "Patient Mobile",
        sort: "asc"
      },
      {
        field: "patientMrn",
        label: "Patient MRN/IC",
        sort: "asc"
      },
      {
        field: "releaseDate",
        label: "Redemption Code Release Date",
        sort: "asc"
      },
      {
        field: "redemptionDate",
        label: "Actual Redemption Date",
        sort: "asc"
      },
      {
        field: "month",
        label: "Month",
        sort: "asc"
      },
      {
        field: "year",
        label: "Year",
        sort: "asc"
      },
      {
        field: "mpgRedeemed",
        label: "MPG Redeemed",
        sort: "asc"
      },
      {
        field: "skuRedeemed",
        label: "SKU Redeemed",
        sort: "asc"
      },
      {
        field: "quantityRedeemed",
        label: "Quantity Redeemed",
        sort: "asc"
      },
      {
        field: "se",
        label: "SE",
        sort: "asc"
      },
      {
        field: "abm",
        label: "ABM",
        sort: "asc"
      }
    ],
    rows: data ? data : []
  };

  let hcpIcMrnTableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "redemptionCode",
        label: "Redemption Code",
        sort: "asc"
      },
      {
        field: "redeemStatus",
        label: "Redeem Status",
        sort: "asc"
      },
      {
        field: "redemptionType",
        label: "Redemption Type",
        sort: "asc"
      },
      {
        field: "patientMobile",
        label: "Patient Mobile",
        sort: "asc"
      },
      {
        field: "patientIc",
        label: "Patient IC",
        sort: "asc"
      },
      {
        field: "patientMrn",
        label: "Patient MRN",
        sort: "asc"
      },
      {
        field: "releaseDate",
        label: "Redemption Code Release Date",
        sort: "asc"
      },
      {
        field: "redemptionDate",
        label: "Actual Redemption Date",
        sort: "asc"
      },
      {
        field: "month",
        label: "Month",
        sort: "asc"
      },
      {
        field: "year",
        label: "Year",
        sort: "asc"
      },
      {
        field: "mpgRedeemed",
        label: "MPG Redeemed",
        sort: "asc"
      },
      {
        field: "skuRedeemed",
        label: "SKU Redeemed",
        sort: "asc"
      },
      {
        field: "quantityRedeemed",
        label: "Quantity Redeemed",
        sort: "asc"
      },
      {
        field: "se",
        label: "SE",
        sort: "asc"
      },
      {
        field: "abm",
        label: "ABM",
        sort: "asc"
      }
    ],
    rows: data ? data : []
  };

  let adminTableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "redemptionCode",
        label: "Redemption Code",
        sort: "asc"
      },
      {
        field: "redeemStatus",
        label: "Redeem Status",
        sort: "asc"
      },
      {
        field: "redemptionType",
        label: "Redemption Type",
        sort: "asc"
      },
      {
        field: "releaseDate",
        label: "Redemption Code Release Date",
        sort: "asc"
      },
      {
        field: "redemptionDate",
        label: "Actual Redemption Date",
        sort: "asc"
      },
      {
        field: "month",
        label: "Month",
        sort: "asc"
      },
      {
        field: "year",
        label: "Year",
        sort: "asc"
      },
      {
        field: "mpgRedeemed",
        label: "MPG Redeemed",
        sort: "asc"
      },
      {
        field: "skuRedeemed",
        label: "SKU Redeemed",
        sort: "asc"
      },
      {
        field: "quantityRedeemed",
        label: "Quantity Redeemed",
        sort: "asc"
      },
      {
        field: "se",
        label: "SE",
        sort: "asc"
      },
      {
        field: "abm",
        label: "ABM",
        sort: "asc"
      }
    ],
    rows: data ? data : []
  };

  let salesTableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "redeemStatus",
        label: "Redeem Status",
        sort: "asc"
      },
      {
        field: "redemptionType",
        label: "Redemption Type",
        sort: "asc"
      },
      {
        field: "releaseDate",
        label: "Redemption Code Release Date",
        sort: "asc"
      },
      {
        field: "redemptionDate",
        label: "Actual Redemption Date",
        sort: "asc"
      },
      {
        field: "month",
        label: "Month",
        sort: "asc"
      },
      {
        field: "year",
        label: "Year",
        sort: "asc"
      },
      {
        field: "mpgRedeemed",
        label: "MPG Redeemed",
        sort: "asc"
      },
      {
        field: "skuRedeemed",
        label: "SKU Redeemed",
        sort: "asc"
      },
      {
        field: "quantityRedeemed",
        label: "Quantity Redeemed",
        sort: "asc"
      },
      {
        field: "se",
        label: "SE",
        sort: "asc"
      },
      {
        field: "abm",
        label: "ABM",
        sort: "asc"
      }
    ],
    rows: data ? data : []
  };

  let csv = [];

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const dataObject = {
        hcpType: data[i].hcpType,
        accountName: data[i].accountName,
        accountCode: data[i].accountCode,
        patientId: data[i].patientId,
        redeemStatus: data[i].redeemStatus,
        redemptionType: data[i].redemptionType,
        releaseDate: data[i].releaseDate,
        redemptionDate: data[i].redemptionDate,
        month: data[i].month,
        year: data[i].year,
        mpgRedeemed: data[i].mpgRedeemed,
        skuRedeemed: data[i].skuRedeemed,
        quantityRedeemed: data[i].quantityRedeemed,
        se: data[i].se,
        abm: data[i].abm,
        patientIc: data[i].patientIc,
        patientMrn: data[i].patientMrn
      }
      if (role === "dev" || role === "hcp") {
        dataObject.patientMobile = `=""${data[i].patientMobile}""`;
      }
      if (role !== "sales" && !hideHcpRedemptionCode) {
        dataObject.redemptionCode = data[i].redemptionCode;
      }
      csv.push(dataObject);
    }
  }

  const hcpCsvData = {
    headers: [
      { label: "HCP Type", key: "hcpType" },
      { label: "Account Name", key: "accountName" },
      { label: "Account Code", key: "accountCode" },
      { label: "Patient ID", key: "patientId" },
      { label: "Redemption Code", key: "redemptionCode" },
      { label: "Redeem Status", key: "redeemStatus" },
      { label: "Redemption Type", key: "redemptionType" },
      { label: "Patient Mobile", key: "patientMobile" },
      { label: "Patient MRN/IC", key: "patientMrn" },
      { label: "Redemption Code Release Date", key: "releaseDate" },
      { label: "Actual Redemption Date", key: "redemptionDate" },
      { label: "Month", key: "month" },
      { label: "Year", key: "year" },
      { label: "MPG Redeemed", key: "mpgRedeemed" },
      { label: "SKU Redeemed", key: "skuRedeemed" },
      { label: "Quantity Redeemed", key: "quantityRedeemed" },
      { label: "SE", key: "se" },
      { label: "ABM", key: "abm" },
    ],
    data: csv
  }

  const hcpIcMrnCsvData = {
    headers: [
      { label: "HCP Type", key: "hcpType" },
      { label: "Account Name", key: "accountName" },
      { label: "Account Code", key: "accountCode" },
      { label: "Patient ID", key: "patientId" },
      { label: "Redemption Code", key: "redemptionCode" },
      { label: "Redeem Status", key: "redeemStatus" },
      { label: "Redemption Type", key: "redemptionType" },
      { label: "Patient Mobile", key: "patientMobile" },
      { label: "Patient IC", key: "patientIc" },
      { label: "Patient MRN", key: "patientMrn" },
      { label: "Redemption Code Release Date", key: "releaseDate" },
      { label: "Actual Redemption Date", key: "redemptionDate" },
      { label: "Month", key: "month" },
      { label: "Year", key: "year" },
      { label: "MPG Redeemed", key: "mpgRedeemed" },
      { label: "SKU Redeemed", key: "skuRedeemed" },
      { label: "Quantity Redeemed", key: "quantityRedeemed" },
      { label: "SE", key: "se" },
      { label: "ABM", key: "abm" },
    ],
    data: csv
  }

  const adminCsvData = {
    headers: [
      { label: "HCP Type", key: "hcpType" },
      { label: "Account Name", key: "accountName" },
      { label: "Account Code", key: "accountCode" },
      { label: "Patient ID", key: "patientId" },
      { label: "Patient MRN/IC", key: "patientMrn" },
      { label: "Redemption Code", key: "redemptionCode" },
      { label: "Redeem Status", key: "redeemStatus" },
      { label: "Redemption Type", key: "redemptionType" },
      { label: "Redemption Code Release Date", key: "releaseDate" },
      { label: "Actual Redemption Date", key: "redemptionDate" },
      { label: "Month", key: "month" },
      { label: "Year", key: "year" },
      { label: "MPG Redeemed", key: "mpgRedeemed" },
      { label: "SKU Redeemed", key: "skuRedeemed" },
      { label: "Quantity Redeemed", key: "quantityRedeemed" },
      { label: "SE", key: "se" },
      { label: "ABM", key: "abm" },
    ],
    data: csv
  }

  const salesCsvData = {
    headers: [
      { label: "HCP Type", key: "hcpType" },
      { label: "Account Name", key: "accountName" },
      { label: "Account Code", key: "accountCode" },
      { label: "Patient ID", key: "patientId" },
      { label: "Redeem Status", key: "redeemStatus" },
      { label: "Redemption Type", key: "redemptionType" },
      { label: "Redemption Code Release Date", key: "releaseDate" },
      { label: "Actual Redemption Date", key: "redemptionDate" },
      { label: "Month", key: "month" },
      { label: "Year", key: "year" },
      { label: "MPG Redeemed", key: "mpgRedeemed" },
      { label: "SKU Redeemed", key: "skuRedeemed" },
      { label: "Quantity Redeemed", key: "quantityRedeemed" },
      { label: "SE", key: "se" },
      { label: "ABM", key: "abm" },
    ],
    data: csv
  }

  return (
    <div className="d-flex">

      <Drawer
        sx={{
          minWidth: "20%",
          '& .MuiDrawer-paper': {
            minWidth: "20%",
            boxSizing: 'border-box',
            minHeight: "100vh",
            backgroundColor: "#FE7072",
          },
        }}
        anchor="left"
        open={openSidebar}
        variant="persistent">
        <Sidebar />
      </Drawer>

      <Main open={openSidebar}>
        <div className="mt-3 px-3">
          <Header title="PATIENT REDEMPTION REPORT" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

          <div className="report-header mt-3 d-flex py-3 px-3 justify-content-between">
            <div className="col-4">
              <p>This report shows patient's status and types of redemption, medication redeemed once they meet the requirement of redemption mechanics.</p>
            </div>
            <div>
              <div className="d-flex">
                <div>
                  <DatePicker placeholderText="Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control py-3" />
                </div>
                <div className="ps-2">
                  <Autocomplete
                    multiple
                    id="sku"
                    options={medicationSkuList}
                    disableCloseOnSelect
                    value={productList}
                    onChange={(e, value) => {
                      setProductList(value);
                    }}
                    renderOption={(props, medicationSkuList, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          checked={selected}
                        />
                        {medicationSkuList}
                      </li>
                    )}
                    style={{ width: 250, backgroundColor: "white" }}
                    renderInput={(params) => (
                      <TextField {...params} label="SKU" />
                    )}
                  />
                </div>
                <div className="ps-2">
                  <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                </div>
                <div className="ps-2">
                  <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                </div>
                <div className="ps-2">
                  <CSVLink className="btn btn-primary shadow-none admin-button" data={csv} headers={role === "sales" || hideHcpRedemptionCode ? salesCsvData.headers : role === "hcp" && showIcMrn ? hcpIcMrnCsvData.headers : role === "dev" || role === "hcp" ? hcpCsvData.headers : adminCsvData.headers} filename={`patient_redemption_report_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </div>
          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div>
                <MDBDataTableV5 data={role === "sales" || hideHcpRedemptionCode ? salesTableData : role === "hcp" && showIcMrn ? hcpIcMrnTableData : role === "dev" || role === "hcp" ? hcpTableData : adminTableData} hover responsive bordered />
              </div>
            )
          }
        </div>
      </Main>

    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.admin.token,
    role: state.admin.role
  };
};

export default connect(mapStateToProps, null)(PatientRedemptionReport);
