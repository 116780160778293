import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import CloseButton from 'react-bootstrap/CloseButton';

const PatientRegistrationCsv = ({ role, token }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [openSidebar, setOpenSidebar] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        if (!role || !token) {
            navigate('/admin/login');
        } else if (role !== "dev") {
            setError("Unauthorized Access");
            setTimeout(() => navigate('/admin/login'), 3000);
        }
    }, [role, token, navigate]);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setError(null);
        setSuccess(null);
    };

    const handleSubmit = () => {
        if (selectedFile) {
            setLoading(true);
            setError(null);
            setSuccess(null);
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('role', role);
            formData.append('token', token);

            axios.post("/api/admin/registerPatientMRN", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                setLoading(false);
                console.log(typeof(res.data.results.errors.join('\n')))
                // setSuccess("File uploaded successfully");
                if(res.data.results.success.length > 0) {
                    setSuccess("File uploaded successfully", res.data.results.success);
                }
                if(res.data.results.errors.length > 0) {
                    console.log("test")
                    setError(res.data.results.errors);
                }
                console.log(error)
                setSelectedFile(null);
            })
            .catch(err => {
                setLoading(false);
                if (err.response) {
                    switch (err.response.status) {
                        case 401:
                            setError("Unauthorized: Please log in again");
                            setTimeout(() => navigate('/admin/login'), 3000);
                            break;
                        case 403:
                            setError("Forbidden: You don't have permission to perform this action");
                            break;
                        case 413:
                            setError("File too large: Please upload a smaller file");
                            break;
                        case 415:
                            setError("Unsupported file type: Please upload a .csv, .xlsx, or .xls file");
                            break;
                        case 500:
                            setError("Server error: Please try again later");
                            break;
                        default:
                            setError(err.response.data.error || "An error occurred");
                    }
                } else if (err.request) {
                    setError("Network error: Please check your internet connection");
                } else {
                    setError("An unexpected error occurred");
                }
            });
        } else {
            setError("Please select a file");
        }
    };
    const handleClose = () => {
        setError(null);
        window.location.reload();
    }
    return (
        <div className="hcp-registration-layout">
            <div className={`sidebar ${openSidebar ? 'open' : ''}`}>
                <Sidebar />
            </div>

            <main className={`main-content ${openSidebar ? 'sidebar-open' : ''}`}>
                <Header title="PATIENT REGISTRATION" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

                <div className="hcp-registration-upload">
                    <h2>Upload Patient Registration File</h2>
                    {error && Array.isArray(error) && error.length > 0 ? (
                        <div className="message error">
                            <CloseButton onClick={handleClose} style={{'float': 'right'}}/>
                            <ul>
                                {error.map((error, index) => (
                                <li key={index}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    ) : error ? (
                        <div className="message error">{error}</div>
                    ) : null}
                    {success && (
                        <div className="message success">{success}</div>
                    )}
                    <div className="file-upload-area">
                        <label htmlFor="file-upload" className="file-upload-label">
                            <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                accept=".csv"
                            />
                            {selectedFile ? selectedFile.name : "Choose a file"}
                        </label>
                    </div>
                    <button
                        onClick={handleSubmit}
                        className={`submit-button ${loading ? 'loading' : ''}`}
                        disabled={!selectedFile || loading}
                    >
                        {loading ? "Uploading..." : "Submit"}
                    </button>
                    <div>
                        <div style={{'margin': '10px auto', 'textAlign': 'center'}}>
                            <h7>Medication Details</h7>
                            <table>
                                <tr>
                                    <th>Brand</th>
                                    <th>Medication</th>
                                    <th>SKU</th>
                                </tr>
                                <tr>
                                    <td>Adaxil Sachets</td>
                                    <td>750 mg/600 mg</td>
                                    <td>MN400561123AC</td>
                                </tr>
                                <tr>
                                    <td>Caduet</td>
                                    <td>5/40 mg</td>
                                    <td>379400565236</td>
                                </tr>
                                <tr>
                                    <td>Caduet</td>
                                    <td>10/20 mg</td>
                                    <td>379400564926</td>
                                </tr>
                                <tr>
                                    <td>Caduet</td>
                                    <td>5/20 mg</td>
                                    <td>379400564925</td>
                                </tr>
                                <tr>
                                    <td>Caduet</td>
                                    <td>5/10 mg</td>
                                    <td>379400564923</td>
                                </tr>
                                <tr>
                                    <td>Caduet</td>
                                    <td>10/40 mg</td>
                                    <td>379400565235</td>
                                </tr>
                                <tr>
                                    <td>Caduet</td>
                                    <td>10/10 mg</td>
                                    <td>379400564924</td>
                                </tr>
                                <tr>
                                    <td>Celebrex Capsules</td>
                                    <td>200 mg</td>
                                    <td>400570436</td>
                                </tr>
                                <tr>
                                    <td>Inspra Capsules</td>
                                    <td>25 mg</td>
                                    <td>400562897pp</td>
                                </tr>
                                <tr>
                                    <td>Inspra Capsules</td>
                                    <td>50 mg</td>
                                    <td>400565341pp</td>
                                </tr>
                                <tr>
                                    <td>Legalon</td>
                                    <td>140 mg</td>
                                    <td>MN400560297AC</td>
                                </tr>
                                <tr>
                                    <td>Lipitor</td>
                                    <td>20 mg</td>
                                    <td>379400566617</td>
                                </tr>
                                <tr>
                                    <td>Lipitor</td>
                                    <td>80 mg</td>
                                    <td>379400566619</td>
                                </tr>
                                <tr>
                                    <td>Lipitor</td>
                                    <td>10 mg</td>
                                    <td>379400566616</td>
                                </tr>
                                <tr>
                                    <td>Lipitor</td>
                                    <td>40 mg</td>
                                    <td>379400566618</td>
                                </tr>
                                <tr>
                                    <td>Lyrica Capsules</td>
                                    <td>50 mg</td>
                                    <td>400564954</td>
                                </tr>
                                <tr>
                                    <td>Lyrica Capsules</td>
                                    <td>75 mg</td>
                                    <td>400564955</td>
                                </tr>
                                <tr>
                                    <td>Norvasc</td>
                                    <td>5 mg</td>
                                    <td>379400563819</td>
                                </tr>
                                <tr>
                                    <td>Norvasc</td>
                                    <td>10 mg</td>
                                    <td>379400563818</td>
                                </tr>
                                <tr>
                                    <td>Viartril-S Capsules</td>
                                    <td>500 mg</td>
                                    <td>MN400558585AC</td>
                                </tr>
                                <tr>
                                    <td>Viagra</td>
                                    <td>100 mg</td>
                                    <td>379400562860</td>
                                </tr>
                            </table>
                        </div>
                    
                    </div>
                </div>
            </main>

            <style jsx>{`
                .hcp-registration-layout {
                    display: flex;
                    height: 100vh;
                }

                .sidebar {
                    width: 20%;
                    background-color: #FE7072;
                    transition: margin-left 0.3s;
                }

                .sidebar.open {
                    margin-left: 0;
                }

                .main-content {
                    flex-grow: 1;
                    padding: 20px;
                    transition: margin-left 0.3s;
                }

                .main-content.sidebar-open {
                    margin-left: 0%;
                }

                .hcp-registration-upload {
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    margin-top: 20px;
                }

                .hcp-registration-upload h2 {
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                .message {
                    padding: 10px;
                    border-radius: 4px;
                    margin-bottom: 15px;
                }

                .message.error {
                    background-color: #ffebee;
                    color: #c62828;
                    border: 1px solid #ef9a9a;
                }

                .message.success {
                    background-color: #e8f5e9;
                    color: #2e7d32;
                    border: 1px solid #a5d6a7;
                }

                .file-upload-area {
                    margin-bottom: 20px;
                }

                .file-upload-label {
                    display: inline-block;
                    padding: 10px 15px;
                    background-color: #f0f0f0;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .file-upload-label input {
                    display: none;
                }

                .submit-button {
                    display: block;
                    width: 100%;
                    padding: 10px;
                    background-color: #0c0f69;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .submit-button:disabled {
                    background-color: #cccccc;
                    cursor: not-allowed;
                }

                .submit-button.loading {
                    opacity: 0.7;
                }
                table {
                    width: 80%;
                    margin: 10px auto;
                    border-collapse: collapse;
                    font-size: 12px;
                    background-color: #ffffff;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                }
                th, td {
                    border: 1px solid #cfc1c1;
                    padding: 2px;
                    text-align: center;
                }
                th {
                    background-color: #ddd;
                    color: black;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                tr:hover {
                    background-color: #ddd;
                }
                caption {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                .close-btn {
                    display: block;
                    margin: 20px auto;
                    padding: 8px 16px;
                    background-color: #ff4d4d;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    font-size: 14px;
                    cursor: pointer;
                    text-align: center;
                }
                .close-btn:hover {
                    background-color: #ff1a1a;
                }
            `}</style>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role
    };
};

export default connect(mapStateToProps, null)(PatientRegistrationCsv);