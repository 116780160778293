import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import LandingPage from "./hcp/landingPage";

import HcpWelcome from "./hcp/hcpWelcome";
import HcpLogin from "./hcp/hcpLogin";
import HcpForgotPw from "./hcp/hcpForgotPw";
import HcpSignUp from "./hcp/hcpSignUp";
import HcpHome from "./hcp/hcpHome";
import HcpOtp from "./hcp/hcpOtp";
import HcpRequestOtp from "./hcp/hcpRequestOtp";
import HcpSubmission from "./hcp/hcpSubmission";
import HcpTransactionHistory from "./hcp/hcpTransactionHistory";
import HcpTermsAndCondition from "./hcp/hcpTerms&Condition";
import HcpPrivacyPolicy from "./hcp/hcpPrivacyPolicy";
import HcpPatientTermsAndCondition from "./hcp/hcpPatientTermsAndCondition";
import HcpPatientPrivacyPolicy from "./hcp/hcpPatientPrivacyPolicy";
import HcpCheckout from "./hcp/hcpCheckout";
import HcpInstantRedemption from "./hcp/hcpInstantRedemption";
import HcpUpdateProfile from "./hcp/hcpUpdateProfile";
import HcpCheckoutInstantRedemption from "./hcp/hcpCheckoutInstantRedemption";
import HcpCreditBalance from "./hcp/hcpCreditBalance";
import HcpBrandInfo from "./hcp/hcpBrandInfo";
import HcpHelp from "./hcp/hcpHelp";
import HcpAddHomeScreenTutorial from "./hcp/hcpAddHomeScreenTutorial";
import HcpStartTutorial from "./hcp/hcpStartTutorial";
import HcpTutorial1 from "./hcp/hcpTutorial1";
import HcpTutorial2 from "./hcp/hcpTutorial2";
import HcpTutorial3 from "./hcp/hcpTutorial3";
import HcpTutorial4 from "./hcp/hcpTutorial4";
import HcpTutorial5 from "./hcp/hcpTutorial5";

import PatientWelcome from "./patient/patientWelcome";
import PatientLogin from "./patient/patientLogin";
import PatientEnterOtp from "./patient/patientEnterOtp";
import PatientSignupRequestOtp from "./patient/patientSignupRequestOtp";
import PatientSignUp from "./patient/patientSignUp";
import PatientTermsAndCondition from "./patient/patientTermsAndCondition";
import PatientPrivacyPolicy from "./patient/patientPrivacyPolicy";
import PatientEnterOtpSignup from "./patient/patientEnterOtpSignup";
// import PatientTutorial from "./patient/patientTutorial";
import PatientHome from "./patient/patientHome";
import PatientSubmission from "./patient/patientSubmission";
import PatientInbox from "./patient/patientInbox";
import PatientTransactionRules from "./patient/patientTransactionRules";
import PatientBrandInfo from "./patient/patientBrandInfo";
import PatientHelp from "./patient/patientHelp";
// import PatientUpdateProfile from "./patient/patientUpdateProfile";
import PatientAddHomeScreenTutorial from "./patient/patientAddHomeScreenTutorial";
import PatientStartTutorial from "./patient/patientStartTutorial";
import PatientTutorial1 from "./patient/patientTutorial1";
import PatientTutorial2 from "./patient/patientTutorial2";
import PatientTutorial3 from "./patient/patientTutorial3";
import PatientTutorial4 from "./patient/patientTutorial4";
import PatientTutorial5 from "./patient/patientTutorial5";

import AdminLogin from "./admin/adminLogin";
import Dashboard from "./admin/dashboard";
import PatientRegistrationReport from "./admin/patientRegistration";
import PatientRedemptionReport from "./admin/patientRedemption";
import PatientSubmissionReport from "./admin/patientSubmission";
import PendingRedemptionReport from "./admin/pendingRedemption";
//import Profile from "./admin/profile";
import SalesData from "./admin/salesData";
import Validation from "./admin/validation";
import RedemptionReport from "./admin/redemptionReport";
import InventoryUpdate from "./admin/inventoryUpdate";
import HcpEnrollmentRecords from "./admin/hcpEnrollmentRecords";
import HcpPerformances from "./admin/hcpPerformances";
import HcpRegistration from "./admin/hcpRegistration";
import PatientRegistrationCsv from "./admin/patientRegistrationCsv";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />

        <Route exact path="/hcp/welcome" element={<HcpWelcome />} />
        <Route exact path="/hcp/login" element={<HcpLogin />} />
        <Route exact path="/hcp/forgotPw" element={<HcpForgotPw />} />
        <Route exact path="/hcp/signup" element={<HcpSignUp />} />
        <Route exact path="/hcp/home" element={<HcpHome />} />
        <Route exact path="/hcp/requestOtp" element={<HcpRequestOtp />} />
        <Route exact path="/hcp/otp" element={<HcpOtp />} />
        <Route exact path="/hcp/hcpSubmission" element={<HcpSubmission />} />
        <Route exact path="/hcp/hcpTransactionHistory" element={<HcpTransactionHistory />} />
        <Route exact path="/hcp/hcpTermsAndCondition" element={<HcpTermsAndCondition />} />
        <Route exact path="/hcp/hcpPrivacyPolicy" element={<HcpPrivacyPolicy />} />
        <Route exact path="/hcp/patientTermsAndCondition" element={<HcpPatientTermsAndCondition />} />
        <Route exact path="/hcp/patientPrivacyPolicy" element={<HcpPatientPrivacyPolicy />} />
        <Route exact path="/hcp/hcpCheckout" element={<HcpCheckout />} />
        <Route exact path="/hcp/hcpInstantRedemption" element={<HcpInstantRedemption />} />
        <Route exact path="/hcp/hcpUpdateProfile" element={<HcpUpdateProfile />} />
        <Route exact path="/hcp/hcpCheckoutInstantRedemption" element={<HcpCheckoutInstantRedemption />} />
        <Route exact path="/hcp/hcpCreditBalance" element={<HcpCreditBalance />} />
        <Route exact path="/hcp/hcpBrandInfo" element={<HcpBrandInfo />} />
        <Route exact path="/hcp/hcpHelp" element={<HcpHelp />} />
        <Route exact path="/hcp/hcpAddToHomeScreenTutorial" element={<HcpAddHomeScreenTutorial />} />
        <Route exact path="/hcp/hcpStartTutorial" element={<HcpStartTutorial />} />
        <Route exact path="/hcp/hcpTutorial1" element={<HcpTutorial1 />} />
        <Route exact path="/hcp/hcpTutorial2" element={<HcpTutorial2 />} />
        <Route exact path="/hcp/hcpTutorial3" element={<HcpTutorial3 />} />
        <Route exact path="/hcp/hcpTutorial4" element={<HcpTutorial4 />} />
        <Route exact path="/hcp/hcpTutorial5" element={<HcpTutorial5 />} />

        <Route exact path="/patient/patientWelcome" element={<PatientWelcome />} />
        <Route exact path="/patient/patientLogin" element={<PatientLogin />} />
        <Route exact path="/patient/patientEnterOtp" element={<PatientEnterOtp />} />
        <Route exact path="/patient/patientSignUpRequestOtp" element={<PatientSignupRequestOtp />} />
        <Route exact path="/patient/patientSignUp" element={<PatientSignUp />} />
        <Route exact path="/patient/patientTermsAndCondition" element={<PatientTermsAndCondition />} />
        <Route exact path="/patient/patientPrivacyPolicy" element={<PatientPrivacyPolicy />} />
        <Route exact path="/patient/patientEnterOtpSignup" element={<PatientEnterOtpSignup />} />
        {/* <Route exact path="/patient/patientTutorial" element={<PatientTutorial />} /> */}
        <Route exact path="/patient/patientHome" element={<PatientHome />} />
        <Route exact path="/patient/patientSubmission" element={<PatientSubmission />} />
        <Route exact path="/patient/patientInbox" element={<PatientInbox />} />
        <Route exact path="/patient/transactionRules" element={<PatientTransactionRules />} />
        <Route exact path="/patient/patientBrandInfo" element={<PatientBrandInfo />} />
        <Route exact path="/patient/patientHelp" element={<PatientHelp />} />
        {/* <Route exact path="/patient/patientUpdateProfile" element={<PatientUpdateProfile />} /> */}
        <Route exact path="/patient/patientAddToHomeScreenTutorial" element={<PatientAddHomeScreenTutorial />} />
        <Route exact path="/patient/patientStartTutorial" element={<PatientStartTutorial />} />
        <Route exact path="/patient/patientTutorial1" element={<PatientTutorial1 />} />
        <Route exact path="/patient/patientTutorial2" element={<PatientTutorial2 />} />
        <Route exact path="/patient/patientTutorial3" element={<PatientTutorial3 />} />
        <Route exact path="/patient/patientTutorial4" element={<PatientTutorial4 />} />
        <Route exact path="/patient/patientTutorial5" element={<PatientTutorial5 />} />

        <Route exact path="/admin/login" element={<AdminLogin />} />
        <Route exact path="/admin/dashboard" element={<Dashboard />} />
        <Route exact path="/admin/patientRegistrationReport" element={<PatientRegistrationReport />} />
        <Route exact path="/admin/patientRedemptionReport" element={<PatientRedemptionReport />} />
        <Route exact path="/admin/patientSubmissionReport" element={<PatientSubmissionReport />} />
        <Route exact path="/admin/pendingRedemptionReport" element={<PendingRedemptionReport />} />
        {/* <Route exact path="/admin/profile" element={<Profile />} /> */}
        <Route exact path="/admin/salesData" element={<SalesData />} />
        <Route exact path="/admin/validation" element={<Validation />} />
        <Route exact path="/admin/redemptionReport" element={<RedemptionReport />} />
        <Route exact path="/admin/inventoryUpdate" element={<InventoryUpdate />} />
        <Route exact path="/admin/hcpEnrollmentRecords" element={<HcpEnrollmentRecords />} />
        <Route exact path="/admin/hcpPerformances" element={<HcpPerformances />} />
        <Route exact path="/admin/hcpRegistration" element={<HcpRegistration />} />
        <Route exact path="/admin/patientRegistration" element={<PatientRegistrationCsv />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
